import React from 'react';
import get from 'lodash.get';
import { getPrimaryMedia } from 'SharedPartialsLocal/getPrimaryMedia';
import { ReportCard } from '@autovia-uk/polaris-components/components/organisms/ReportCard';
import { asyncTrackLinkClick } from 'Helpers/tracking';

import {
  getRelatedContent,
} from 'Helpers';

export const getReportCard = ({
  props,
  howWeReviewUrl = '',
  extraClassNames = {},
  layout = 'default',
}) => {
  const {
    layoutData: {
      page: {
        associatedContent,
        cons,
        pageId,
        priceMax,
        priceMin,
        primaryMedia,
        product,
        pros,
        rating,
        reviewType,
        specsPath,
        vehicleSpecList: {
          priceRange: {
            capPriceMax,
            capPriceMin,
          },
        },
        marketplaceLink,
      },
    },
  } = props;

  const reviewUrl = get(props, 'layoutData.page.ownerReview.url', null);
  const pageUrl = get(props, 'layoutData.page.url', null);

  const ownerReviewUrlsData = ['/audi/a1', '/fiat/500', '/ford/focus', '/mg/zs', '/bmw/2-series'];

  let ownerReviewCtaItem = {};

  const filteredOwnerReviewCtaItem = ownerReviewUrlsData.filter(item => item === pageUrl);

  if (filteredOwnerReviewCtaItem && filteredOwnerReviewCtaItem.length > 0) {
    ownerReviewCtaItem = {
      label: 'Submit your review',
      copy: 'Owner reviews with',
      image: {
        src: '/public/logo-carbuyer.svg',
      },
      ctaUrl: `https://www.carbuyer.co.uk${filteredOwnerReviewCtaItem[0]}/owner-reviews`,
    };
  }

  const relatedContent = getRelatedContent(associatedContent, 'relatedPages');

  const min = capPriceMin || priceMin;
  const max = capPriceMax || priceMax;
  const priceOptions = (min !== 0 || max !== 0) ? {
    min,
    max,
    minOnly: false,
    prefixText: 'from ',
    priceSeparator: '–',
  } : {};

  const rrpSaving = props?.layoutData?.page?.newCarDealsPricing?.vehicleFinanceData?.rrpSaving;
  const rrpSavingsPriceOptions = (rrpSaving != null && rrpSaving >= 0) ? {
    min: rrpSaving,
    minOnly: true,
    postText: ' off RRP*',
  } : {};

  let overAllRating = rating;
  const shouldHaveHowWeReviewLink = (reviewType && ['car', 'van'].includes(reviewType)) || false;

  let tabRating;
  const ratingsRounding = 1;
  if (relatedContent !== null && relatedContent.length > 0 && relatedContent[0].id !== pageId) {
    overAllRating = relatedContent[0].rating || 0;
    const currentSection = relatedContent.filter(item => item.id === pageId);
    if (currentSection && Array.isArray(currentSection) && currentSection.length) {
      tabRating = {
        showTextRating: true,
        showTextRatingWithTotal: false,
        value: rating,
        label: `${currentSection[0].title || 'This Section'} rating`,
        rounding: ratingsRounding,
        ctaLabel: shouldHaveHowWeReviewLink ? `How we review ${reviewType}s` : null,
        ctaUrl: shouldHaveHowWeReviewLink && howWeReviewUrl ? howWeReviewUrl : null,
      };
    }
  }

  let ratings = [];
  if (overAllRating > 0) {
    ratings = [
      {
        label: 'Overall Auto Express rating',
        value: overAllRating,
        showTextRating: true,
        showTextRatingWithTotal: false,
        rounding: ratingsRounding,
        ctaLabel: shouldHaveHowWeReviewLink ? `How we review ${reviewType}s` : null,
        ctaUrl: shouldHaveHowWeReviewLink && howWeReviewUrl ? howWeReviewUrl : null,
      },
    ];
  }

  if (tabRating) {
    ratings.pop(); // clear the ratings array so only the tab rating is shown
    ratings.push(tabRating);
  }

  const ctas = [];

  // ReportCard won't render if these are empty so neither will we
  if (!pros && !cons && ratings.length === 0 && Object.keys(priceOptions).length === 0) {
    return false;
  }

  if (reviewUrl !== null) {
    ctas.push({
      title: 'OWNER REVIEWS',
      url: reviewUrl,
    });
  }

  if (marketplaceLink?.url && marketplaceLink?.title) {
    ctas.push({
      title: marketplaceLink.title,
      url: marketplaceLink.url,
      onClick: async () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
          await asyncTrackLinkClick({
            event: 'marketplace.button_click',
            'event.type': 'GYC',
            'marketplace_button.placement': 'GYCReportCardBuyButton',
          });
        }
      },
      extraClassNames: {
        'polaris__report-card--cta--marketplace-button': true,
      },
    });
  }

  if (product && specsPath) {
    ctas.push({
      title: 'SPECIFICATIONS',
      url: specsPath,
    });
  }

  /**
   * Checks if the report card has content to display.
   *
   * @returns {boolean} - Returns true if the report card has content, otherwise false.
   */
  const hasReportCardContent = () => (
    (ratings.length > 0 && ratings[0].value !== 0) // Check if there are ratings and the first rating value is not zero
      || Object.keys(priceOptions).length > 0 // Check if there are price options
      || pros // Check if there are pros
      || cons // Check if there are cons
      || ctas.length > 0 // Check if there are call-to-actions (CTAs)
      || Object.keys(ownerReviewCtaItem).length > 0 // Check if there is an owner review CTA item
  );

  // Returns false if the report card does not have content to display.
  if (!hasReportCardContent()) {
    return false;
  }

  return (
    <ReportCard
      cons={cons}
      consIcon=""
      consLabel="Cons"
      ctas={ctas}
      extraClassNames={extraClassNames || undefined}
      extraComponent={getPrimaryMedia(primaryMedia, '-report-card')}
      layout={layout}
      ownerReviewCta={ownerReviewCtaItem}
      priceOptions={priceOptions}
      rrpSavingsPriceOptions={rrpSavingsPriceOptions}
      pros={pros}
      prosIcon=""
      prosLabel="Pros"
      ratings={ratings}
      ratingsRounding={ratingsRounding}
    />
  );
};
