import React from 'react';
import { BuyBox } from 'Organisms/BuyBox';
import { SimpleBuyBox } from 'Molecules/SimpleBuyBox';
import { ErrorBoundary } from '@autovia-uk/polaris-components/components/organisms/ErrorBoundary';
import { isMarketplaceLink } from 'Helpers';

/**
 * Formats a URL by appending UTM parameters if the URL is not a marketplace link and utm parameters are provided.
 *
 * @param {string} url - The URL to format.
 * @param {string} [utm] - The UTM parameters to append to the URL.
 * @returns {string} - The formatted URL.
 */
const formatUrl = (url, utm) => {
  if (!url) return '';
  if (!url.startsWith('http')) return url;
  return isMarketplaceLink(url) ? url : url + (utm ? (url.includes('?') ? '&' : '?') + utm : '');
};

const PartnerType = {
  Marketplace: 'marketplace',
  DemandGen: 'demandGen',
  LandingPage: 'landingpage',
};

export const getBuyBox = ({
  props,
  type = 'buybox',
}) => {
  const {
    config: {
      carwow,
    },
    dataLayer,
    layoutData: {
      page: {
        priceMax,
        priceMin,
        newCarDealsData,
        leasingVehicleData,
        newCarDealsPricing,
        usedVehicleData,
        vehicleSpecList: {
          priceRange: {
            capPriceMax,
            capPriceMin,
          },
        },
      },
    },
  } = props;

  const vehicleMake = dataLayer?.make;
  const vehicleModel = dataLayer?.productFamilyShortName;

  const newCarDealsCtaLink = newCarDealsData?.ctaLink?.url;
  const newCarDealsImage = newCarDealsData?.image;

  const leasingCtaLink = leasingVehicleData?.vehicleCta?.url || carwow?.buybox?.leasing?.ctaUrl;

  const newCarPricingLink = newCarDealsPricing?.vehicleItem?.url;
  const newCarPricingSaving = newCarDealsPricing?.vehicleFinanceData?.rrpSaving;

  const usedCarPrice = usedVehicleData?.vehicleFinanceData?.usedPrice;
  const usedCarLink = usedVehicleData?.vehicleCta?.url;
  const usedCarLinkTitle = usedVehicleData?.vehicleCta?.title;

  const min = capPriceMin || priceMin;
  const max = capPriceMax || priceMax;
  const newCarDealsPriceOptions = (min !== 0 || max !== 0) ? {
    min,
    max,
    minOnly: false,
    prefixText: 'from ',
    priceSeparator: '–',
  } : {};

  /**
   * @type {{extraItem: {ctaUrl: *, ctaId: string, extraClassName: string, ctaText: string, partnerDetails: {image: {disableTransforms: boolean, size: string, src: string, alt: string, width: number, height: number}}, title: string, subtitle: string, ctaGA4Label: string, ctaGA4Partner: string}, subtitle: string, description: string, title: (string|string), items: [{ctaUrl: string, ctaId: string, extraClassName: string, ctaText: string, price: {first: number, firstPostText: string}, partnerDetails: {image: {disableTransforms: boolean, size: string, src: string, alt: string, width: number, height: number}}, title: string, ctaGA4Label: string, ctaGA4Partner: string, fallback: string}]}}
   */
  const buyBoxData = {
    title: (vehicleMake && vehicleModel) ? `Find your ${vehicleMake} ${vehicleModel}` : 'Find your next car here',
    description: 'Compare deals from trusted partners on this car and previous models.',
    items: [
      {
        title: 'Compare leasing deals**',
        ctaId: 'buyBox-link-2',
        ctaText: 'Leasing deals link',
        ctaUrl: formatUrl(leasingCtaLink, carwow?.buybox?.leasing?.ctaUrlUtm),
        ctaGA4Label: 'leasingdeals',
        ctaGA4Partner: PartnerType.Marketplace,
        extraClassName: '-leasingdeals',
        price: {
          first: +leasingVehicleData?.vehicleItem?.financePrice,
          firstPostText: ' pm',
          firstPreText: 'From ',
        },
        fallback: leasingVehicleData?.vehicleCta?.title,
        simpleBuyBox: {
          type: 'leasingDeals',
          price: {
            title: 'Leasing',
            savings: +leasingVehicleData?.vehicleItem?.financePrice,
            savingsPreText: 'From ',
            savingsPostText: ' pm**',
          },
          cta: {
            type: 'leasing',
            label: 'Leasing deals',
            url: formatUrl(leasingCtaLink, carwow?.buybox?.leasing?.ctaUrlUtm),
            trackingData: {
              event: 'buybox.button_click',
              'buybox.button': 'mar-25_ab_leasing_deals',
              'buybox.partner': PartnerType.Marketplace,
            },
          },
        },
      },
    ],
    subtitle: 'Or are you looking to sell your car?',
    extraItem: {
      ...carwow?.buybox?.sellYourCar,
      partnerDetails: carwow?.buybox?.buyBoxLogo,
      title: carwow?.buybox?.sellYourCar?.ctaText,
      ctaId: 'buyBox-link-4',
      ctaText: carwow?.buybox?.sellYourCar?.ctaText,
      ctaUrl: formatUrl(carwow?.buybox?.sellYourCar?.ctaUrl, carwow?.buybox?.sellYourCar?.ctaUrlUtm),
      ctaGA4Label: 'sellyourcar',
      ctaGA4Partner: PartnerType.LandingPage,
      fallback: carwow?.buybox?.sellYourCar?.subtitle,
    },
    extraClassNames: {
      noskim: true,
    },
    partnerLogo: {
      image: {
        src: newCarDealsImage?.src || '/public/partners/partner--new-car.svg',
        alt: newCarDealsImage?.alt || 'New car deals logo',
        height: newCarDealsImage?.height || 10,
        width: newCarDealsImage?.width || 10,
        size: newCarDealsImage ? 'manufacturer-logo' : '',
      },
    },
  };

  if (usedCarPrice) {
    buyBoxData.items.unshift({
      title: 'Used car offers',
      ctaId: 'buyBox-link-3',
      ctaText: usedCarLinkTitle || 'Used car offers',
      ctaUrl: usedCarLink,
      ctaGA4Label: 'usedcardeals',
      ctaGA4Partner: PartnerType.Marketplace,
      extraClassName: '-usedCars',
      price: {
        savings: usedCarPrice,
        savingsPreText: 'From ',
      },
      simpleBuyBox: {
        type: 'usedCarDeals',
        price: {
          title: 'Used',
          savings: usedCarPrice,
          savingsPreText: 'From ',
        },
        cta: {
          label: 'New & Used deals',
          url: usedCarLink,
          trackingData: {
            event: 'buybox.button_click',
            'buybox.button': 'mar-25_ab_new_used_deals',
            'buybox.partner': PartnerType.Marketplace,
          },
        },
      },
    });
  }

  // Only add newCarDeals section if there is appropriate data.
  if (newCarDealsData?.title && newCarDealsData?.ctaLink) {
    let isCarwowLink = false;

    if (newCarDealsData?.ctaLink?.url.startsWith('http')) { // Basic check for valid url
      const newCarDealsLinkURL = new URL(newCarDealsData?.ctaLink?.url);
      isCarwowLink = newCarDealsLinkURL.hostname.includes('carwow.co.uk');
    }

    buyBoxData.items.unshift({
      title: newCarDealsData?.title || 'New car deals',
      ctaId: 'buyBox-link-1',
      ctaText: newCarDealsData?.ctaLink?.title || 'New car deals',
      ctaUrl: newCarDealsCtaLink,
      ctaGA4Label: 'newcardeals',
      ctaGA4Partner: PartnerType.DemandGen,
      extraClassName: `-newcardeals${isCarwowLink ? ' -carwow' : ''}`,
      fallback: newCarDealsData?.ctaLink?.title,
    });
  } else if (newCarPricingLink && newCarPricingSaving) {
    buyBoxData.items.unshift({
      title: carwow?.buybox?.newCarDeals?.title || 'Buy with Auto Express',
      ctaId: 'buyBox-link-1',
      ctaText: carwow?.buybox?.newCarDeals?.title || 'Buy with Auto Express',
      ctaUrl: formatUrl(newCarPricingLink, carwow?.buybox?.newCarDeals?.ctaUrlUtm),
      ctaGA4Label: 'newcardeals',
      ctaGA4Partner: PartnerType.Marketplace,
      extraClassName: '-carwow',
      price: {
        savings: newCarPricingSaving,
        savingsPreText: carwow?.buybox?.newCarDeals?.subtitlePreText || 'Avg. saving ',
        savingsPostText: carwow?.buybox?.newCarDeals?.subtitlePostText || ' off RRP*',
      },
      fallback: 'New car deals',
      simpleBuyBox: {
        type: 'newCarDeals',
        price: {
          title: 'Avg. savings ',
          savings: newCarPricingSaving,
          savingsPreText: '',
          savingsPostText: carwow?.buybox?.newCarDeals?.subtitlePostText || ' off RRP*',
        },
        rrpPrice: {
          title: 'RRP',
          ...newCarDealsPriceOptions,
        },
        cta: {
          label: 'New & Used deals',
          url: formatUrl(newCarPricingLink, carwow?.buybox?.newCarDeals?.ctaUrlUtm),
          trackingData: {
            event: 'buybox.button_click',
            'buybox.button': 'mar-25_ab_new_used_deals',
            'buybox.partner': PartnerType.Marketplace,
          },
        },
      },
    });
  }

  if (type === 'simpleBuyBox') {
    const ctas = [];
    const newCarDealsItem = buyBoxData.items.find(item => item.simpleBuyBox?.type === 'newCarDeals');
    const usedCarDealsItem = buyBoxData.items.find(item => item.simpleBuyBox?.type === 'usedCarDeals');
    const otherItems = buyBoxData.items.filter(item => item.simpleBuyBox?.type && item.simpleBuyBox?.type !== 'newCarDeals' && item.simpleBuyBox?.type !== 'usedCarDeals');

    if (newCarDealsItem) {
      ctas.push({ ...newCarDealsItem.simpleBuyBox.cta });
    } else if (usedCarDealsItem) {
      ctas.push({ ...usedCarDealsItem.simpleBuyBox.cta });
    }

    otherItems.forEach((item) => {
      ctas.push({ ...item.simpleBuyBox.cta });
    });

    buyBoxData.ctas = ctas;

    buyBoxData.items = buyBoxData.items.map((item) => {
      const { price, rrpPrice } = item.simpleBuyBox || {};
      return {
        ...(price && price.savings && { price }),
        ...(rrpPrice && { rrpPrice }),
      };
    });

    return (
      <SimpleBuyBox
        {...buyBoxData}
        extraClassNames={{
          ...buyBoxData.extraClassNames,
        }}
      />
    );
  }

  return (
    <ErrorBoundary>
      <BuyBox {...buyBoxData} id="polaris__buybox" />
    </ErrorBoundary>
  );
};
