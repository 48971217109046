import React from 'react';
import { DisqusCount } from '@autovia-uk/polaris-components/components/molecules/DisqusCount';
import { PostMeta } from '@autovia-uk/polaris-components/components/molecules/PostMeta';

import { getAuthors } from 'Helpers';

export const getPostMeta = (page, enableComments, siteUrl, dataLayer, disqusShortname) => {
  const {
    date,
    categories,
    tags,
    url,
    title,
    contentType,
    layoutType,
  } = page;

  let { authors } = page;

  const {
    nodeId,
  } = dataLayer || {};

  if (contentType === 'OWNER_REVIEW') {
    authors = false;
  }

  if (layoutType === 'commercialPage' || layoutType === 'commercialPageTitleImage') {
    authors = false;
  }

  return (
    <div className="polaris__simple-grid">
      <div className="polaris__simple-grid--main">
        <PostMeta
          date={date}
          authorPrefix="By:"
          categories={categories}
          tags={tags}
          {...getAuthors(authors)}
          extraComponent={
            enableComments
            && disqusShortname
            && (
              <DisqusCount
                identifier={`node/${nodeId}`}
                url={`${siteUrl}${url}`}
                title={title}
                shortname={disqusShortname}
                commentsContainer="#polaris_comments"
              />
            )
          }
        />
      </div>
    </div>
  );
};
