import get from 'lodash.get';
import cloneDeep from 'lodash.clonedeep';

import config from 'Config/config.json';
/**
 * Wiring Helper
 *
 * This contains functions used to assist with wiring together data and display.
 */

/**
 * Extracts authors for PostMeta component.
 * @param {Array} authors Array of authors from middleware.
 * @returns {Object}
 */
export function getAuthors(authors) {
  if (authors === false) {
    return false;
  }

  const author = {
    author: 'Auto Express team',
    authorImage: { src: '/public/logo-auto-express.svg?v2' },
  };

  const authorsList = [];
  const maxAuthors = 3;

  if (authors && Array.isArray(authors)) {
    if (authors.length === 1) {
      author.author = authors[0].name;
      author.authorUrl = authors[0].url;
      author.authorImage = { src: authors[0].image ? authors[0].image.src : '' };
    } else {
      authors.forEach(({ name, url, image }, index) => {
        if (index < maxAuthors) {
          authorsList.push({ author: name, authorUrl: url, authorImage: { src: image ? image.src : '' } });
        }
      });
    }
  }

  return authorsList.length > 1 ? { authors: authorsList } : { ...author };
}

/**
 * Extracts content from array of vehicleSpecList.
 * @param {Array} trims Array of objects that have a type and content key.
 */
export function getMultipageVehicleSpecs(trims) {
  if (!trims || !trims.length) return [];
  return trims.map(trim => trim.items)
    .reduce((result = [], item) => result.concat(item))
    .map(item => ({ url: item.path, title: item.name }));
}

/**
 * Extracts content from array of related content.
 * @param {Array} related Array of objects that have a type and content key.
 * @param {String} key    What key to look for. Defaults to most popular.
 */
export function getRelatedContent(related, key) {
  const item = related.find(({ type }) => type === key);
  if (!item) {
    return [];
  }
  return item.content;
}

/**
 * Extracts content for the ArticleGroup component.
 * @param {Array} related Array of objects that have a type and content key.
 * @param {String} key    What key to look for. Defaults to most popular.
 * @param {Array} injectedItem  Array of objects that have an injected item, and the position where the item should be placed in the array.
 */
export function getArticleGroupContent(
  related,
  key = 'mostPopular',
  injectedItem,
) {
  const relatedContent = getRelatedContent(related, key).map(item => ({
    ...item,
    title: item.altTitle || item.title,
  }));

  // Inject the item into the relatedContent array and remove the last one
  if (injectedItem) {
    // @TODO: pass the position from the template, temp solution
    // relatedContent.splice(injectedItem.position, 1, injectedItem.component);
    relatedContent.splice(-1, 1, injectedItem);
  }

  return relatedContent;
}

/**
 * Extracts content for the ArticleGroup component.
 * @param {Array} related Array of objects that have a type and content key.
 * @param {String} key    What key to look for. Defaults to similar products.
 */
export function getSimilarProductContent(related, key = 'similarProducts') {
  return getArticleGroupContent(related, key).map(item => ({
    ...item,
    // without extra class names ArticleGroup won't render data
    extraClassNames: {
      '-default': true,
    },
  }));
}

/**
 * Extracts content for the ArticleGroup component.
 * @param {Array} related Array of objects that have a type and content key.
 * @param {String} key    What key to look for. Defaults to recommended.
 */
export function getRecommendedContent(related, key = 'recommended') {
  return getArticleGroupContent(related, key);
}


/**
 * Extract a specific value from the data layer, if it exists.
 * @param {String} key   The string to look for.
 * @param {Array} meta   The data layer.
 * @param {*} defaultVal Default value to return if not found.
 */
export function getDataLayerValue(key, meta, defaultVal = null) {
  return (meta.find(({ key: itemKey }) => itemKey === key) || {}).value || defaultVal;
}

/**
 * Gets the title with page number if set.
 * @param {String} title   The page title.
 * @param {Number} pageNum The current page number.
 * @returns {String}       The page title with page number.
 */
export function getPaginatedTitle(title, pageNum) {
  return (!pageNum || pageNum === 1) ? title : `${title} | page ${pageNum}`;
}

/**
 * Wires up the footer promo box.
 * @param {Object} promoSettings Promo box settings.
 * @returns {Object}
 */
export function getFooterPromo(promoSettings = {}) {
  if (!promoSettings) {
    return {};
  }
  const { shortText } = promoSettings;
  if (shortText) {
    return { ...promoSettings, excerpt: shortText };
  }
  return promoSettings;
}

/**
 * Make relative path absolute.
 * @param {String} path    The relative path.
 * @param {String} siteUrl The site base.
 * @returns {String}
 */
export function toAbsolute(path, siteUrl) {
  // Make sure it's not already absolute.
  if (path.indexOf('http') === 0) {
    return path;
  }
  try {
    return new URL(path, siteUrl);
  } catch (e) {
    return path;
  }
}

/**
 * Get index article page contents from props.
 *
 * @param props
 * @return {boolean|*}
 */
export function getIndex(props) {
  const { layoutData: { page: { associatedContent } } } = props;
  const content = associatedContent.find(({ type }) => type === 'indexArticlePageList');
  if (!content) {
    return false;
  }

  return content;
}

/**
 * Reformat header menu parent item.
 *
 * @param element
 * @return {*}
 */
export function reformatHeaderMenuElement(element) {
  if (element.children !== null && Array.isArray(element.children) && element.children.length > 0) {
    /* eslint no-param-reassign: [2, { "props": false }] */
    element.children = element.children.map((item) => {
      const newItem = { ...item };
      if (newItem.item !== null) {
        newItem.url = newItem.item.url;
      }

      return newItem;
    });

    const firstElem = element.children[0];
    if (firstElem && firstElem.item && firstElem.item.__typename === 'TaxonomyIndex' && firstElem.url === element.url) {
      element.viewAll = true;
      delete element.children[0];
    }
  }

  return element;
}

export function addTopLevelFieldToNavigationMenuItemsWithChildren(menuItemsArray) {
  if (!menuItemsArray) return false;
  const arrCopy = cloneDeep(menuItemsArray);
  arrCopy.forEach((parentObject) => {
    reformatHeaderMenuElement(parentObject);
  });

  return arrCopy;
}

export const getRightSideAdConfig = (adCount = 1) => {
  const adConfig = get(config, 'ads[slot-2]');

  if (adConfig) {
    adConfig.extraClassNames = {
      '-sidebar-ad': true,
    };
    adConfig.id = `refresh-right_${adCount}`;
    adConfig.isSidebar = true;
    adConfig.isSkippable = true;
    adConfig.targeting = {
      position: `right_${adCount}`,
      placement: `right_${adCount}`,
      refresh: 'yes',
    };

    return adConfig;
  }

  return false;
};

/**
 * Format article group item for whitepaper
 *
 * @param {*} item
 * @param article
 */
export const formatPageItemForWhitepaper = (item, article) => {
  if (article.__typename && article.__typename === 'WhitepaperResource') {
    item.resourceCategory = {
      resourceLabel: article.contentType ? article.contentType.toLowerCase() : '',
      resourceUrl: article.link || '#',
    };
    item.imageSize = 'resource-card';
  }

  return item;
};

/**
 * getCustomizedAdConfig
 * Based on config from the Portal, generate the slotSize and sizeMapping for the ad
 *
 * @param {Boolean} desktopRule
 * @param {Boolean} mobileRule
 * @return customizedAdConfig
 */
export const getCustomizedAdConfig = ({
  desktopRule,
  mobileRule,
}) => {
  const customizedAdConfig = [];

  customizedAdConfig.slotSize = [[300, 250], 'fluid'];
  customizedAdConfig.sizeMapping = [
    {
      viewport: [0, 0],
      slot: mobileRule ? [[300, 250], 'fluid'] : [],
    },
    {
      viewport: [760, 0],
      slot: mobileRule ? [[728, 90], [300, 250], 'fluid'] : [],
    },
    {
      viewport: [980, 0],
      slot: desktopRule ? [[970, 250], [970, 90], [728, 90], 'fluid'] : [],
    },
  ];

  return customizedAdConfig;
};

/**
 * Check if the page is `articleType` In-depth reviews and `reviewType` car
 *
 * @param articleType
 * @param reviewType
 */
export const isInDepthCarReview = ({ articleType = '', reviewType = '' }) => articleType && articleType === 'In-depth reviews' && reviewType && reviewType === 'car';

/**
 * Check if the page is `articleType` Long-term tests and `reviewType` car
 *
 * @param articleType
 * @param reviewType
 */
export const isLongTermTestReview = ({ articleType = '', reviewType = '' }) => articleType && articleType === 'Long-term tests' && reviewType && reviewType === 'car';

/**
 * Check if the page is `articleType` Road tests and `reviewType` car
 *
 * @param articleType
 * @param reviewType
 */
export const isRoadTestReview = ({ articleType = '', reviewType = '' }) => articleType && articleType === 'Road tests' && reviewType && reviewType === 'car';

/**
 * Check if the page has the one of the layoutTypes which should be using one column layout
 * @param layoutType
 */
export const isOneColumnLayout = ({ layoutType = '' }) => (layoutType ? [
  'adFreeTemplate',
  'landingPage',
  'editorialLandingPage',
  'commercialPage',
  'commercialPageTitleImage',
  'Full width',
  'fullWidth',
  'plainFullWidth',
].includes(layoutType) : false);

const marketplaceUrlRegex = /^marketplace.autoexpress.co.uk$/;

/**
 * Determines if a given URL is an autoexpress marketplace link by checking its hostname
 * @param {string} url - The URL to check
 * @returns {boolean} - Returns true if the URL's hostname exactly matches 'marketplace.autoexpress.co.uk', false otherwise
 *
 * @example
 * isMarketplaceLink('https://marketplace.autoexpress.com') // returns true
 * isMarketplaceLink('https://other.autoexpress.com') // returns false
 * isMarketplaceLink(null) // returns false
 */
export const isMarketplaceLink = (url) => {
  if (!url || typeof url !== 'string') {
    return false;
  }

  try {
    const { hostname } = new URL(url);

    return marketplaceUrlRegex.test(hostname);
  } catch {
    return false;
  }
};
