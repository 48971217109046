/**
 * Get the layout configuration based on the layout type.
 *
 * @param {string} layout - The type of layout.
 * @returns {Object} The layout configuration.
 */
export function useLayout(layout) {
  const ads = {
    hideBelowHeaderAd: false,
    hidePageImpressionAd: false,
    hideOOPsAds: false,
    hideSidebar: false,
    hideStickyFooterAd: false,
    hideAfterContentAd: false,
  };

  const headerComponents = {
    hideHeaderNavigation: false,
    hideProductSelector: false,
  };

  const topComponents = {
    showHero: false,
    showTitleOnHero: false,
    showCommercialSponsor: false,
    hideArticleSponsor: false,
    hideBreadcrumbs: false,
    hideSubtitle: false,
    hideTitleInLayout: false,
    hidePostMeta: false,
  };

  const bodyComponents = {
    hidePrimaryMedia: false,
    hideInThisArticle: false,
  };

  const bottomComponents = {
    hideTags: false,
    hideSocialButtons: false,
    hideDisqus: false,
    hideShortAuthorBio: false,
    hideGycSmcPromoBlock: false,
    hideRecommended: false,
    hideMostPopular: false,
    hideIndexArticles: false,
    hideNewsletter: false,
  };

  const footerComponents = {
    useReducedFooter: false,
  };

  const baseLayout = {
    bodyLandingPageClass: false,
    bodyCommercialPageClass: false,
    headerLandingPageClass: false,
    fullwidth: false,
    ...ads,
    ...headerComponents,
    ...topComponents,
    ...bodyComponents,
    ...bottomComponents,
    ...footerComponents,
  };

  switch (layout) {
    case 'Full Width':
    case 'fullWidth':
      return {
        ...baseLayout,
        fullwidth: true,
        hideBreadcrumbs: true,
        hideSubtitle: true,
        hideSidebar: true,
        hideMostPopular: true,
        hideBelowHeaderAd: true,
        hideStickyFooterAd: true,
      };
    case 'Landing Page':
    case 'landingPage':
      return {
        ...baseLayout,
        bodyLandingPageClass: true,
        fullwidth: true,
        hideBreadcrumbs: true,
        hideTitleInLayout: true,
        hideSubtitle: true,
        hideSidebar: true,
        hideMostPopular: true,
        hideBelowHeaderAd: true,
        hidePageImpressionAd: true,
        hideOOPsAds: true,
        hideStickyFooterAd: true,
        useReducedFooter: true,
        headerLandingPageClass: true,
        hideHeaderNavigation: true,
        hideProductSelector: true,
      };
    case 'plainFullWidth':
      return {
        ...baseLayout,
        bodyLandingPageClass: true,
        fullwidth: true,
        hideBreadcrumbs: true,
        hideTitleInLayout: true,
        hideSubtitle: true,
        hideSidebar: true,
        hideMostPopular: true,
        hideBelowHeaderAd: true,
        hideStickyFooterAd: true,
        hideProductSelector: true,
      };
    case 'commercialPage':
      return {
        ...baseLayout,
        fullwidth: true,
        bodyCommercialPageClass: true,
        hideBreadcrumbs: true,
        hidePrimaryMedia: true,
        hideArticleSponsor: true,
        showHero: true,
        hideBelowHeaderAd: true,
        hideOOPsAds: true,
        hideStickyFooterAd: true,
        showCommercialSponsor: true,
        hidePostMeta: true,
        hideInThisArticle: true,
        hideTags: true,
        hideSocialButtons: true,
        hideDisqus: true,
        hideShortAuthorBio: true,
        hideGycSmcPromoBlock: true,
        hideAfterContentAd: true,
        hideRecommended: true,
        hideMostPopular: true,
        hideIndexArticles: true,
        hideNewsletter: true,
      };
    case 'commercialPageTitleImage':
      return {
        ...baseLayout,
        fullwidth: true,
        bodyCommercialPageClass: true,
        hideBreadcrumbs: true,
        hidePrimaryMedia: true,
        hideArticleSponsor: true,
        showHero: true,
        showTitleOnHero: true,
        hideTitleInLayout: true,
        hideBelowHeaderAd: true,
        hideOOPsAds: true,
        hideStickyFooterAd: true,
        showCommercialSponsor: true,
        hidePostMeta: true,
        hideInThisArticle: true,
        hideTags: true,
        hideSocialButtons: true,
        hideDisqus: true,
        hideShortAuthorBio: true,
        hideGycSmcPromoBlock: true,
        hideAfterContentAd: true,
        hideRecommended: true,
        hideMostPopular: true,
        hideIndexArticles: true,
        hideNewsletter: true,
      };
    case 'editorialLandingPage':
      return {
        ...baseLayout,
        fullwidth: true,
        hideAfterContentAd: true,
        hideArticleSponsor: true,
        hideBelowHeaderAd: true,
        hideDisqus: true,
        hideGycSmcPromoBlock: true,
        hideIndexArticles: true,
        hideInThisArticle: true,
        hideMostPopular: true,
        hideNewsletter: true,
        hideOOPsAds: true,
        hideRecommended: true,
        hideShortAuthorBio: true,
        hideSocialButtons: true,
        hideStickyFooterAd: true,
        hideTags: true,
        showHero: true,
      };
    case 'defaultBasicPage':
      return {
        ...baseLayout,
        hideTitleInLayout: true,
      };
    default:
      return {
        ...baseLayout,
      };
  }
}
