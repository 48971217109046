import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { Price } from '@autovia-uk/polaris-components/components/atoms/Price';
import { asyncTrackLinkClick } from 'Helpers/tracking';

import './_style.scss';

/**
 * SimpleBuyBox component
 *
 * @param {Object} props - The component props
 * @param {Object} props.context - The context object containing component configuration
 * @param {string} props.context.cssPrefix - CSS prefix for component styling
 * @param {Array} props.ctas - Array of call-to-action objects
 * @param {string} props.description - Description text for the buy box
 * @param {Object} props.extraClassNames - Additional CSS class names to apply
 * @param {Array} props.items - Array of item objects to display in the buy box
 * @param {Object} props.partnerLogo - Partner logo object
 * @param {string} props.title - Title text for the buy box
 * @returns {JSX.Element} A simple buy box component with cta buttons
 */

const SimpleBuyBox = ({
  context: {
    cssPrefix,
  },
  ctas,
  description,
  extraClassNames,
  items,
  partnerLogo,
  title,
}) => (
  <div className={classNames({
    [`${cssPrefix}__simple-buybox`]: true,
    ...extraClassNames,
  })}
  >
    <div className={`${cssPrefix}__simple-buybox--header`}>
      <div className={`${cssPrefix}__simple-buybox--header-content`}>
        <p className={`${cssPrefix}__simple-buybox--title`}>{title}</p>
        <p className={`${cssPrefix}__simple-buybox--description`}>{description}</p>
      </div>
      <div className={`${cssPrefix}__simple-buybox--header-logo`}>
        <Image {...partnerLogo?.image} />
      </div>
    </div>

    <div className={`${cssPrefix}__simple-buybox--items`}>
      {items?.map((item, itemIndex) => {
        if (!item) return null;
        return (
          <React.Fragment key={`simple-buybox-item_${itemIndex}`}>
            {item.rrpPrice && (
              <div className={`${cssPrefix}__simple-buybox--item --rrp`}>
                <div className={`${cssPrefix}__simple-buybox--item--title`}>{item.rrpPrice.title}</div>
                <Price
                  extraClassNames={{
                    [`${cssPrefix}__simple-buybox--item--price`]: true,
                    [`${cssPrefix}__simple-buybox--item--price--rrp`]: true,
                  }}
                  {...item.rrpPrice}
                />
              </div>
            )}
            {item.price?.savings && (
              (typeof item.price.savings === 'number' && !Number.isNaN(item.price.savings)) ? (
                <div className={`${cssPrefix}__simple-buybox--item${item.rrpPrice ? ' --has-rrp' : ''}`}>
                  <div className={`${cssPrefix}__simple-buybox--item--title`}>{item.price.title}</div>
                  <Price
                    extraClassNames={{
                      [`${cssPrefix}__simple-buybox--item--price`]: true,
                      [`${cssPrefix}__simple-buybox--item--price--savings`]: true,
                    }}
                    minOnly
                    min={item.price.savings}
                    prefixText={item.price.savingsPreText}
                    postText={item.price.savingsPostText}
                  />
                </div>
              ) : null
            )}
          </React.Fragment>
        );
      })}
    </div>

    <div className={`${cssPrefix}__simple-buybox--ctas`}>
      {ctas?.map((cta, ctaIndex) => {
        if (!cta) return null;
        return (
          <Link
            key={`simple-buybox-cta_${ctaIndex}`}
            href={cta.url}
            extraClassNames={{
              [`${cssPrefix}__simple-buybox--cta`]: true,
              '--secondary': cta.type && cta.type === 'leasing',
              '-link-as-button': true,
            }}
            onClick={async () => { await asyncTrackLinkClick(cta.trackingData); }}
          >
            {cta.label}
          </Link>
        );
      })}
    </div>
  </div>
);

SimpleBuyBox.propTypes = {
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
  ctas: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    trackingData: PropTypes.shape(),
    type: PropTypes.string,
    url: PropTypes.string,
  })),
  description: PropTypes.string,
  extraClassNames: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    price: PropTypes.shape({
      title: PropTypes.string,
      savings: PropTypes.number,
      savingsPreText: PropTypes.string,
      savingsPostText: PropTypes.string,
    }),
    rrpPrice: PropTypes.shape({
      title: PropTypes.string,
      min: PropTypes.number,
      max: PropTypes.number,
      minOnly: PropTypes.bool,
      prefixText: PropTypes.string,
      priceSeparator: PropTypes.string,
    }),
  })),
  partnerLogo: PropTypes.shape({
    image: PropTypes.shape(),
  }),
  title: PropTypes.string,
};

SimpleBuyBox.defaultProps = {
  context: {
    cssPrefix: 'polaris',
  },
  ctas: [],
  description: '',
  extraClassNames: {},
  items: [],
  partnerLogo: {},
  title: '',
};

export default withPolaris(SimpleBuyBox);
