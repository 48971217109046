import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { compose } from 'recompose';

import {
  HtmlSafe,
  withPagination,
} from 'polaris-coreweb/exports';

import injectionEngine from '@carwow/injection-engine';
import rules from '@carwow/injection-default-rules-set';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import {
  getArticleGroupContent,
  getIndex,
  getRelatedContent,
} from 'Helpers';
import { setPageNumber } from 'SharedHelpers/setPageNumber';
import { countBodyInjectedInlineAds } from 'SharedHelpers/countBodyInjectedInlineAds';
import { getPromoBoxSettings } from 'SharedPartialsLocal/getPromoBoxSettings';

import { getAfterContentAd } from 'SharedPartials/getAfterContentAd';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { getBreadcrumbs } from 'SharedPartialsLocal/getBreadcrumbs';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { getIndexArticlePageHeader } from 'SharedPartials/getIndexArticlePageHeader'; // Heading and TaxonomyFilter
import { getDisqusCollapsible } from 'SharedPartialsLocal/getDisqusCollapsible'; // only has Link
import { getMultipageNavigation } from 'SharedPartialsLocal/getMultipageNavigation';
import { getInThisArticle } from 'SharedPartialsLocal/getInThisArticle'; // NextPages
import { getNewsletter } from 'SharedPartialsLocal/getNewsletter';
import { getPostMeta } from 'SharedPartialsLocal/getPostMeta'; // DisqusCount and PostMeta: Date, Image, Link
import { getPrimaryMedia } from 'SharedPartialsLocal/getPrimaryMedia'; // Image, Link, Podcast, Social, SocialEmbed, Video, Gallery
import { ShortAuthorBio } from '@autovia-uk/polaris-components/components/molecules/ShortAuthorBio';
import { getSocialSharingButtons } from 'SharedPartialsLocal/getSocialSharingButtons';
import { getSponsorInfo } from 'SharedPartials/getSponsorInfo';
import { getTags } from 'SharedPartialsLocal/getTags';
import { getArticleGroup } from 'SharedPartialsLocal/getArticleGroup';
import { getBody } from 'SharedPartialsLocal/getBody';
import { Hero } from '@autovia-uk/polaris-components/components/organisms/Hero';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { getIndexArticlePageContent } from 'SharedPartials/getIndexArticlePageContent'; // Ad, LoadMore and ArticleGroup
import { getArticleBottomComponents } from 'SharedPartialsLocal/getArticleBottomComponents'; //  has getNextPages and getRelatedContent
import { GycSmcPromoBlock } from 'Molecules/GycSmcPromoBlock';
import { useLayout } from 'Helpers/hooks/useLayout';
import { asyncTrackLinkClick } from 'Helpers/tracking';

import { ArticleQueryPagination as query } from './ArticleQuery.graphql';

// Template styles and imports
import 'Styles/components/templates/_Article.scss';

const Article = (props) => {
  const {
    config: {
      breakpoints,
      globalSettings: {
        adSettings,
        adSettings: { afterContentDesktopAds, afterContentMobileAds },
        disqusShortname,
        enableComments,
        promoBoxSettings,
      },
      newsletterConfig,
      siteUrl,
      social: {
        share: { platforms: socialSharingPlatforms },
      },
    },
    dataLayer,
    layoutData: {
      page: {
        articleType,
        associatedContent,
        authors,
        body,
        breadcrumbs,
        filters,
        hideTitle,
        heroImage,
        isSponsored,
        kicker,
        layoutType,
        monetising,
        primaryMedia,
        showFilters,
        sponsor: sponsorInfo,
        subtitle,
        tags,
        title,
        url,
      },
      page,
    },
    isMobile,
  } = props;

  const isAdFreeTemplate = layoutType === 'adFreeTemplate';
  const isCommercialPageTitleImage = layoutType === 'commercialPageTitleImage';
  const {
    heroImage: heroImageDesktop, heroImageMobile, heroImageTitlePosition, heroImageCta,
  } = heroImage || {};
  const hasHeroImage = Object.keys(heroImage).length > 0 && (heroImageDesktop || heroImageMobile);

  const {
    bodyCommercialPageClass,
    fullwidth,
    hideAfterContentAd,
    hideArticleSponsor,
    hideBreadcrumbs,
    hideDisqus,
    hideGycSmcPromoBlock,
    hideIndexArticles,
    hideInThisArticle,
    hideMostPopular,
    hideNewsletter,
    hidePrimaryMedia,
    hidePostMeta,
    hideRecommended,
    hideShortAuthorBio,
    hideSocialButtons,
    hideTitleInLayout,
    hideSubtitle,
    hideTags,
    showCommercialSponsor,
    showHero,
    showTitleOnHero,
  } = useLayout(layoutType);

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;

  const taxonomyFilterParams = {
    content: taxonomyFilters,
    currentPageUrl: url,
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See all',
      seeLessLabel: 'See less',
      icon: 'down',
    },
  };

  const { nodeId } = dataLayer;
  const indexData = getIndex(props);
  const isIndexArticlePage = indexData !== false;
  const multipage = getRelatedContent(associatedContent, 'relatedPages');

  const usedDealsBlock = Array.isArray(associatedContent) ? associatedContent.find(({ type }) => type === 'usedDealsBlock') : null;
  const { usedCarDeals } = usedDealsBlock ?? {};

  let bodyInjected = injectionEngine({
    body,
    rules,
    props: {
      adSettings,
      breakpoints,
      monetising,
      newsletterConfig,
      pageContentType: 'article',
      promoBoxSettings: getPromoBoxSettings({
        promoBoxSettings,
        ppc: promoBoxSettings.subscriptionArticle,
      }),
    },
  });

  // replace SUBSCRIBE_NEWSLETTER with SHOPWINDOW block
  bodyInjected = bodyInjected && bodyInjected.map((item) => {
    if (item.type === 'SUBSCRIBE_NEWSLETTER') {
      return {
        ...item,
        ...{ type: 'SHOPWINDOW' },
      };
    }
    return { ...item };
  });

  const countInlineAds = countBodyInjectedInlineAds({
    bodyInjected,
    bodyBlockInlineAdDesktop: 'DESKTOP_INLINE_ADVERT',
    bodyBlockInlineAdMobile: 'MOBILE_INLINE_ADVERT',
  });

  const shortAuthorBio = () => {
    if (!authors || !authors.length) return null;

    const author = authors[0];

    // Return the ShortAuthorBio component
    return (
      <ShortAuthorBio
        authorBio={<HtmlSafe html={author.shortBio || ''} />}
        authorLink={author.url}
        authorImage={author.image}
        authorName={author.name}
        authorRole={author.jobTitle}
        singleImage
      />
    );
  };

  const topComponents = (
    <div className="polaris__simple-grid--main">
      {getMultipageNavigation(multipage, url)}
      {!hidePrimaryMedia && getPrimaryMedia(primaryMedia, '-article')}
      {!hideArticleSponsor && getSponsorInfo({ sponsor: sponsorInfo })}
    </div>
  );

  const articleNewsletterConfig = {
    // Create the newsletter config but use newsUrl instead of url
    ...newsletterConfig,
    url: newsletterConfig.newsUrl,
  };

  const isHeroImageTitlePositionConfigured = heroImageTitlePosition === 'left' || heroImageTitlePosition === 'right' || heroImageTitlePosition === 'center';
  const hideBlockIfHeroWithTextIsShown = showHero && isHeroImageTitlePositionConfigured;

  return (
    <>
      <div
        className={classNames({
          polaris__body: true,
          polaris__commercialpage: bodyCommercialPageClass,
        })}
      >
        <main className="polaris__main" id="main">
          {showHero && hasHeroImage && (
            <Hero
              images={[
                heroImageDesktop && {
                  ...heroImageDesktop,
                  size: 'hero-feature-desktop',
                },
                heroImageMobile && {
                  ...heroImageMobile,
                  size: 'hero-feature-mobile',
                },
              ].filter(Boolean)}
              renderPictureTag
              showPrimaryMedia={!!hasHeroImage}
              title={((showTitleOnHero || isHeroImageTitlePositionConfigured || heroImageTitlePosition !== 'none') && title) ? title : undefined}
              subtitle={((!showTitleOnHero && isHeroImageTitlePositionConfigured && heroImageTitlePosition !== 'none') && subtitle) ? subtitle : undefined}
              extraClassNames={{
                'polaris__hero--banner': true,
                'polaris__commercialpage-hero': true,
                'polaris__commercialpagetitleimage-hero': showTitleOnHero,
                [`polaris__heroimagetitleposition-${heroImageTitlePosition}`]: isHeroImageTitlePositionConfigured && heroImageTitlePosition !== 'none',
                '-no-image': !hasHeroImage,
                '-with-text-over-image': !showTitleOnHero && isHeroImageTitlePositionConfigured && heroImageTitlePosition !== 'none',
              }}
            >
              {!showTitleOnHero && isHeroImageTitlePositionConfigured && heroImageTitlePosition !== 'none' && heroImageCta?.url && heroImageCta?.title && (
                <Link
                  href={heroImageCta.url}
                  onClick={async () => {
                    await asyncTrackLinkClick({
                      event: 'marketplace.button_click',
                      'event.type': 'GYC',
                      'marketplace_button.placement': 'LandingPageHero',
                    });
                  }}
                >
                  {heroImageCta.title}
                </Link>
              )}
            </Hero>
          )}

          <div className="polaris__core-content polaris__content">
            {
              showCommercialSponsor && (
                <div>
                  <div className="polaris__commercialpage-sponsor">
                    {getSponsorInfo({
                      sponsor: {
                        ...sponsorInfo,
                        logo: {
                          ...sponsorInfo?.logo,
                          size: 'sponsor-medium',
                        },
                      },
                    })}
                  </div>
                  <div className="polaris__commercialpage-meta">
                    {isSponsored && getBreadcrumbs([], isSponsored, isSponsored ? kicker : '')}
                    {getPostMeta(
                      page,
                      enableComments && !hideDisqus,
                      siteUrl,
                      dataLayer,
                      disqusShortname,
                    )}
                  </div>
                </div>
              )
            }
            {
              !hideBreadcrumbs && !hideBlockIfHeroWithTextIsShown && getBreadcrumbs(breadcrumbs, isSponsored, kicker)
            }

            {!hideTitle && title && !hideTitleInLayout && !isCommercialPageTitleImage && !hideBlockIfHeroWithTextIsShown && (
              <Heading size={1} extraClassNames={{ '-content-title': true }}>
                {title}
              </Heading>
            )}

            {!hideSubtitle && subtitle && !hideBlockIfHeroWithTextIsShown && (
              <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
                {subtitle}
              </Heading>
            )}

            {!hidePostMeta && !hideBlockIfHeroWithTextIsShown && getPostMeta(
              page,
              enableComments && !hideDisqus,
              siteUrl,
              dataLayer,
              disqusShortname,
            )}
          </div>

          <div className={`polaris__content ${fullwidth ? '-full-width' : ''} -body-copy`}>
            {getBody(
              {
                bodyInjected,
                template: 'article',
                layoutType,
                bottomComponents: getArticleBottomComponents({
                  siteUrl,
                  associatedContent,
                  tags,
                  title,
                  url,
                  socialSharingPlatforms,
                }),
                topComponents,
                usedCarDeals,
                isMobile,
              },
            )}

            {!hideInThisArticle && !hideTags
            && !hideSocialButtons && !hideDisqus
            && !hideShortAuthorBio && !hideGycSmcPromoBlock
            && (
              <div className="polaris__core-content polaris__core-content-last polaris__simple-grid--main">
                {!hideInThisArticle && getInThisArticle('Continue Reading', url, multipage, 'currently reading')}
                { !hideTags && !hideSocialButtons && (
                  <div className="polaris__content-bottom">
                    {!hideTags && getTags(tags, false)}
                    {!hideSocialButtons && getSocialSharingButtons({
                      title,
                      siteUrl,
                      url,
                      socialSharingPlatforms,
                      extraClassNames: { 'polaris__article--social': true },
                    })}
                  </div>
                )}
                {!hideDisqus && enableComments && getDisqusCollapsible(
                  siteUrl,
                  url,
                  title,
                  nodeId,
                  disqusShortname,
                )}
                {!hideShortAuthorBio && shortAuthorBio()}
                {// show GycSmcPromoBlock only if articleType is not 'Best cars & vans'
                  !hideGycSmcPromoBlock && articleType && articleType !== 'Best cars & vans'
                  && <GycSmcPromoBlock extraClassNames={{ '-article': true }} />
                }
              </div>
            )}
          </div>
        </main>
      </div>
      {
        (afterContentDesktopAds || afterContentMobileAds)
        && !hideAfterContentAd
        && !isAdFreeTemplate
        && (
          getAfterContentAd({ afterContentDesktopAds, afterContentMobileAds })
        )
      }
      {
        !hideRecommended && !hideMostPopular
        && !hideIndexArticles && !hideNewsletter
        && (
          <div className="polaris__core-content polaris__content polaris__core-content-first">
            {!hideRecommended && getArticleGroup({
              title: 'Recommended',
              content: getArticleGroupContent(associatedContent, 'recommended'),
              extraClassNames: { 'polaris__article--consider': true },
              articleCardProps: {
                datePosition: 'bottom',
                headingSize: 4,
                kickerPosition: 'bottom',
              },
            })}
            {!hideMostPopular && !isIndexArticlePage
                && getArticleGroup({
                  title: 'Most Popular',
                  content: getArticleGroupContent(associatedContent, 'mostPopular', {
                    component: [
                      <React.Fragment key="native_article">
                        {getNativeAd({
                          id: 'native_article',
                          targeting: {
                            position: 'native_article',
                            placement: 'native_article',
                            refresh: 'no',
                          },
                        })}
                      </React.Fragment>,
                    ],
                  }),
                  extraClassNames: { 'polaris__article--most-popular': true },
                  articleCardProps: {
                    datePosition: 'bottom',
                    isHeading: false,
                    kickerPosition: 'bottom',
                  },
                })
              }
            {!hideIndexArticles && isIndexArticlePage && (
              <>
                {getIndexArticlePageHeader({
                  indexData,
                  propsData: props,
                  showFilters: showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0,
                  taxonomyFilterParams,
                })}
                {getIndexArticlePageContent(
                  indexData,
                  {
                    ...props,
                    articleCardProps: {
                      datePosition: 'bottom',
                      headingSize: 4,
                      kickerPosition: 'bottom',
                    },
                    articlesPerRow: 4,
                  },
                  countInlineAds,
                  isMobile,
                )}
              </>
            )}
            {// show Newsletter only if articleType is not 'Best cars & vans'
              !hideNewsletter && articleType && articleType !== 'Best cars & vans' && getNewsletter({
                ...articleNewsletterConfig,
                extraClassNames: {
                  ...articleNewsletterConfig.extraClassNames,
                  '-article': true,
                },
              })
            }
          </div>
        )
      }
    </>
  );
};

Article.propTypes = {
  config: PropTypes.shape({
    ads: PropTypes.shape(),
    breakpoints: PropTypes.shape({
      desktop: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
      mobile: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
    globalSettings: PropTypes.shape({
      adSettings: PropTypes.shape({
        afterContentDesktopAds: PropTypes.bool,
        afterContentMobileAds: PropTypes.bool,
        afterNavigationDesktopAds: PropTypes.bool,
        afterNavigationMobileAds: PropTypes.bool,
        listGalleryAds: PropTypes.number,
        minNumOfWordsAfterLastInlineAdDesktop: PropTypes.number,
        minNumOfWordsAfterLastInlineAdMobile: PropTypes.number,
        minNumOfWordsBeforeTeads: PropTypes.number,
        numberOfWordsBetweenInlineDesktopAds: PropTypes.number,
        numberOfWordsBetweenInlineMobileAds: PropTypes.number,
        numberOfWordsForFirstInjectionDesktop: PropTypes.number,
        numberOfWordsForFirstInjectionMobile: PropTypes.number,
        refreshBlacklist: PropTypes.string,
        refreshSiteWide: PropTypes.bool,
        refreshTime: PropTypes.number,
      }),
      disqusShortname: PropTypes.string,
      enableComments: PropTypes.bool,
      monetising: PropTypes.arrayOf(
        PropTypes.shape({
          config: PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.string,
              value: PropTypes.string,
            }),
          ),
          enabled: PropTypes.bool,
          service: PropTypes.string,
        }),
      ),
      promoBoxSettings: PropTypes.shape(),
    }).isRequired,
    newsletterConfig: PropTypes.shape(),
    siteUrl: PropTypes.string.isRequired,
    social: PropTypes.shape({
      share: PropTypes.shape({
        platforms: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string,
          platform: PropTypes.string,
        })),
      }),
    }),
  }).isRequired,
  dataLayer: PropTypes.shape({
    make: PropTypes.string,
    nodeId: PropTypes.string,
    product: PropTypes.string,
    productFamily: PropTypes.string,
    productFamilyShortName: PropTypes.string,
    productShortName: PropTypes.string,
  }),
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf().isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  pagination: PropTypes.shape({
    hasMore: PropTypes.bool,
    isLoading: PropTypes.bool,
    LoadMore: PropTypes.func,
    loadMore: PropTypes.func,
    nextUrl: PropTypes.string,
    page: PropTypes.number,
    pages: PropTypes.objectOf(),
    setPageRef: PropTypes.func,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

Article.defaultProps = {
  dataLayer: {},
  location: {
    search: '',
  },
};

const MemoArticle = React.memo(Article);

export default compose(
  setPageNumber,
  withPagination({
    query,
    path: ({ associatedContent }) => getRelatedContent(associatedContent, 'indexArticlePageList'),
  }),
  withTemplate,
  withPolaris,
  withHelmet('article'),
)(MemoArticle);
