import React from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { asyncTrackLinkClick } from 'Helpers/tracking';

import './_style.scss';

const PATHS = {
  BUY_A_CAR: 'https://marketplace.autoexpress.co.uk/buy-a-car',
  CAR_NEWS: '/car-news',
  CAR_REVIEWS: '/car-reviews',
  SELL_MY_CAR: '/sell-my-car',
};

const commonTrackingData = {
  event: 'marketplace.button_click',
};

const TRACKING_DATA = {
  BUY: {
    ...commonTrackingData,
    'event.type': 'GYC',
    'marketplace_button.placement': 'GYCStickyFooter',
    'marketplace.button': 'Buy',
  },
  SELL: {
    ...commonTrackingData,
    'event.type': 'SMC',
    'marketplace_button.placement': 'SMCStickyFooter',
    'marketplace.button': 'Sell',
  },
  NO_NONSENSE_CAR_SELLING: {
    ...commonTrackingData,
    'event.type': 'SMC',
    'marketplace_button.placement': 'SMCStickyFooter',
    'marketplace.button': 'SellNoNonsense',
  },
};

/**
 * Generate tracking data for sticky footer menu button clicks
 * @param {string} button - The name of the button clicked
 * @returns {Object} Tracking data object with event name and button information
 */
const getStickyFooterTrackingData = button => ({
  event: 'sticky_footer_menu.button_click',
  'sticky_footer.button': button,
});

/**
 * Mobile navigation component
 * @name MobileNavigation
 * @param {Object} props - Component props
 * @param {Object} props.context - Context object
 * @param {string} props.context.cssPrefix - CSS prefix for styling
 * @param {string} props.pathname - Current pathname
 * @returns {JSX.Element}
 */
const MobileNavigation = ({
  context: {
    cssPrefix,
  },
  pathname,
}) => (
  <div className={`${cssPrefix}__mobile-navigation`}>
    {pathname !== PATHS.SELL_MY_CAR && (
      <Link
        href={PATHS.SELL_MY_CAR}
        extraClassNames={{
          [`${cssPrefix}__mobile-navigation__cta`]: true,
        }}
        onClick={async () => {
          await Promise.allSettled([
            asyncTrackLinkClick(TRACKING_DATA.NO_NONSENSE_CAR_SELLING),
            asyncTrackLinkClick(getStickyFooterTrackingData('SellNoNonsense')),
          ]);
        }}
      >
        No-nonsense car selling has arrived
      </Link>
    )}

    <nav className={`${cssPrefix}__mobile-navigation__nav`} aria-label="Mobile navigation">
      <ul className={`${cssPrefix}__mobile-navigation__list`}>
        <li>
          <Link
            href={PATHS.CAR_REVIEWS}
            extraClassNames={{
              [`${cssPrefix}__mobile-navigation__item`]: true,
              '-star-half-icon': true,
              '-active': pathname === PATHS.CAR_REVIEWS,
            }}
            onClick={async () => {
              await asyncTrackLinkClick(getStickyFooterTrackingData('Reviews'));
            }}
          >
            Reviews
          </Link>
        </li>
        <li>
          <Link
            href={PATHS.CAR_NEWS}
            extraClassNames={{
              [`${cssPrefix}__mobile-navigation__item`]: true,
              '-news-2-icon': true,
              '-active': pathname === PATHS.CAR_NEWS,
            }}
            onClick={async () => {
              await asyncTrackLinkClick(getStickyFooterTrackingData('News'));
            }}
          >
            News
          </Link>
        </li>
        <li>
          <Link
            href={PATHS.BUY_A_CAR}
            extraClassNames={{
              [`${cssPrefix}__mobile-navigation__item`]: true,
              '-deals-icon': true,
            }}
            onClick={async () => {
              await Promise.allSettled([
                asyncTrackLinkClick(TRACKING_DATA.BUY),
                asyncTrackLinkClick(getStickyFooterTrackingData('Buy')),
              ]);
            }}
          >
            Buy
          </Link>
        </li>
        <li>
          <Link
            href={PATHS.SELL_MY_CAR}
            extraClassNames={{
              [`${cssPrefix}__mobile-navigation__item`]: true,
              '-tag-icon': true,
              '-active': pathname === PATHS.SELL_MY_CAR,
            }}
            onClick={async () => {
              await Promise.allSettled([
                asyncTrackLinkClick(TRACKING_DATA.SELL),
                asyncTrackLinkClick(getStickyFooterTrackingData('Sell')),
              ]);
            }}
          >
            Sell
          </Link>
        </li>
      </ul>
    </nav>
  </div>
);

MobileNavigation.propTypes = {
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
  pathname: PropTypes.string.isRequired,
};

MobileNavigation.defaultProps = {
  context: {},
};

export default withPolaris(MobileNavigation);
