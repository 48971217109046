/**
 * @render react
 * @name Footer
 * @example
 * <Footer />
 * Example when mobile sticky ad should not be rendered on the page:
 * <Footer mobileStickyAd={false} />
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';

import {
  HtmlSafe,
  withConfig,
  useStore,
} from 'polaris-coreweb/exports';


import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Navigation } from '@autovia-uk/polaris-components/components/molecules/Navigation'; // Button, NavigationItem (Icon and Link)
import { Social } from '@autovia-uk/polaris-components/components/molecules/Social'; // Icon, Link
import BannerEvent from 'Organisms/BannerEvent/BannerEvent'; // Heading, Popup
import MobileNavigation from 'Organisms/MobileNavigation/MobileNavigation';
import { useLayout } from 'Helpers/hooks/useLayout';

import './_style.scss';
// HELPERS
// In CMS new lines added via special symbols \r\n
// swap them for line break tag
// simple swap '\r\n' -> '<br />' in string doesn't give wanted solution, so making react components is a must
// https://medium.com/@kevinsimper/react-newline-to-break-nl2br-a1c240ba746
const renderCMSText = (str) => {
  if (typeof str !== 'string') return '';

  const newLine = '\r\n';
  if (!str.includes(newLine)) return str;

  return (
    <>
      {
        str.split(newLine).map((line, lineIndex) => (
          <React.Fragment key={`line_${lineIndex}`}>
            {line}
            <br />
          </React.Fragment>
        ))
      }
    </>
  );
};

const getPrivacyPreferenceLink = ({ privacyManagerId }) => ({
  title: 'Privacy Preferences',
  url: '#',
  __typename: 'MenuItem',
  onClick: (e) => {
    e.preventDefault();
    if (typeof window._sp_ === 'undefined'
    || typeof window._sp_.loadPrivacyManagerModal === 'undefined') {
      return;
    }
    window._sp_.loadPrivacyManagerModal(privacyManagerId);
  },
});

/**
 * Footer component
 *
 * @param {string} props.context.footer.legalDisclaimer - Legal disclaimer text.
 * @param {Object} props.context.footer.navigation - The navigation configuration object.
 * @param {Object} props.context.social - Social media configuration.
 * @param {string} props.context.globalSettings.copyright - Copyright text.
 * @param {string} props.layoutType - The layout type.
 */
const Footer = (props) => {
  const {
    context: {
      cssPrefix,
      footer: {
        legalDisclaimer,
        navigation,
      },
      globalSettings: {
        copyright,
        sourcepoint,
      },
      social,
    },
    isMobile,
    layoutData,
    layoutType,
    location,
    mobileStickyAd,
  } = props;

  // Experiment setup start
  const { pathname } = location;
  const abTestTracked = useRef(false);
  const experiment = useStore(state => state.userExperiments);
  const isNavExperimentVariant1Active = isMobile && experiment?.mobileBottomNavigationExperiment === 'variantA';

  useEffect(() => {
    if (isMobile && !abTestTracked.current && experiment?.mobileBottomNavigationExperiment) {
      window.dataLayer.push({
        event: 'entered_ab_test',
        test_name: 'mobile-bottom-navigation-hornbeam-apr-25',
        test_variant: experiment.mobileBottomNavigationExperiment,
      });
      abTestTracked.current = true;
    }
  }, [isMobile, experiment?.mobileBottomNavigationExperiment]);
  // Experiment setup end

  const footerNavigation = navigation && navigation.footer && [...navigation.footer];
  const reducedFooterNavigation = navigation && navigation?.reducedfooter && [...navigation?.reducedfooter];

  const layout = layoutData?.page?.fullWidth ? 'fullWidth' : layoutType;
  const {
    hideStickyFooterAd,
    useReducedFooter,
  } = useLayout(layout);

  if (sourcepoint?.privacyManagerId) {
    footerNavigation.push(getPrivacyPreferenceLink(sourcepoint));
  }

  if (useReducedFooter && reducedFooterNavigation && sourcepoint?.privacyManagerId) {
    // eslint-disable-next-line no-unused-expressions
    reducedFooterNavigation?.push(getPrivacyPreferenceLink(sourcepoint));
  }

  return (
    <>
      <div className="screen-reader-text">
        <a href="#header">Skip to Header</a>
        <a href="#main">Skip to Content</a>
      </div>
      <footer
        className={classNames({
          [`${cssPrefix}__footer`]: true,
        })}
        id="footer"
      >
        <div className={`${cssPrefix}__footer--inner`}>
          <div className={`${cssPrefix}__footer--row`}>
            {useReducedFooter && reducedFooterNavigation
              ? (
                <div className={`${cssPrefix}__footer--navigation`}>
                  {navigation?.reducedfooter && (
                  <Navigation
                    content={reducedFooterNavigation}
                  />
                  )}
                </div>
              )
              : (
                <div className={`${cssPrefix}__footer--navigation`}>
                  {navigation?.footer && (
                  <Navigation
                    content={footerNavigation}
                  />
                  )}
                  <Social
                    platforms={social.follow.platforms}
                    extraClassNames={{ [`${cssPrefix}__footer--social`]: true }}
                  />
                </div>
              )
            }
          </div>

          <div className={`${cssPrefix}__footer--row`}>
            {legalDisclaimer && legalDisclaimer.length > 0 && (
              <div className={`${cssPrefix}__footer--legal-disclaimer`}>
                {legalDisclaimer.map((disclaimer, index) => (
                  <HtmlSafe key={index} html={disclaimer} />
                ))}
              </div>
            )}
            <div className={`${cssPrefix}__footer--copyright`}>
              <p>{renderCMSText(copyright)}</p>
            </div>
          </div>
        </div>

        { // Experiment - Variant 1
          isNavExperimentVariant1Active && (
            <MobileNavigation pathname={pathname} />
          )
        }

        { // Experiment - Control
          !hideStickyFooterAd
          && mobileStickyAd
          && !isNavExperimentVariant1Active
          && (
            <Ad
              id="refresh-stickyfooter"
              type="stickyfooter"
              renderWhenViewable={false}
              targeting={{
                position: 'stickyfooter',
                placement: 'stickyfooter',
                refresh: 'yes',
              }}
              isMobileSticky
            />
          )
        }
      </footer>
      <BannerEvent cssPrefix={cssPrefix} />
    </>
  );
};

Footer.propTypes = {
  context: PropTypes.shape({
    footer: PropTypes.shape({
      legalDisclaimer: PropTypes.arrayOf(PropTypes.string),
      navigation: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.shape(),
        PropTypes.arrayOf(PropTypes.shape({
          url: PropTypes.string,
          title: PropTypes.string,
        })),
      ]),
    }),
    social: PropTypes.shape({
      follow: PropTypes.shape(),
    }),
    globalSettings: PropTypes.shape({
      copyright: PropTypes.string,
      sourcepoint: PropTypes.shape({
        accountId: PropTypes.number,
        ccpa: PropTypes.bool,
        mmsDomain: PropTypes.string,
        privacyManagerId: PropTypes.number,
        propertyHref: PropTypes.string,
        propertyId: PropTypes.number,
      }),
    }),
    cssPrefix: PropTypes.string,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  layoutData: PropTypes.shape(),
  layoutType: PropTypes.string,
  location: PropTypes.shape().isRequired,
  mobileStickyAd: PropTypes.bool,
};

Footer.defaultProps = {
  layoutData: {
    page: {
      fullWidth: false,
    },
  },
  layoutType: '',
  mobileStickyAd: true,
};

export default compose(withConfig, withPolaris)(Footer);
